import React, { SVGProps } from 'react';

export default function VideoLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={179.208}
      height={176.347}
      viewBox="0 0 4699.1 4624.07"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>{".prefix__fil1{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Layer_x0020_1">
        <path
          d="M1524.87 339.98c-314.85-60.82-697.68-54.74-1150.27 21.82-21.82 3.58-40.79 14.67-54.03 30.41-12.87 15.39-20.74 35.79-20.74 58.68v2620.4c438.64-94.09 835.06-106.97 1189.98-39.35 322 61.18 608.59 188.2 859.75 379.6 251.15-191.4 537.73-318.42 859.74-379.6 354.91-67.62 751.34-54.74 1189.98 39.35V450.89c0-22.89-7.51-43.29-20.75-58.68-13.24-15.74-31.84-26.83-54.02-30.41-452.6-76.21-835.07-82.64-1150.27-21.82-301.97 58.32-540.62 178.89-718.43 359.21l-106.25 107.7-106.26-107.7c-177.82-180.31-416.82-300.88-718.43-359.21zM325.58 66.99c488.01-82.29 905.54-88.37 1255.46-20.4 307.33 59.4 563.15 175.68 768.87 345.98 205.71-170.67 461.17-286.58 768.86-345.98 349.55-67.62 767.08-61.89 1255.46 20.4 94.09 15.75 175.32 64.05 232.92 132.03 57.96 68.33 91.95 156.35 91.95 251.52V3083.1c0 43.66-9.66 85.52-28.62 124.87-18.6 38.64-45.44 72.62-79.79 100.19-34.71 27.9-73.34 47.22-115.2 56.89-41.51 9.65-84.08 10.02-127.37.71-404.65-88.02-765.65-101.26-1083.73-40.78-311.63 59.38-583.9 189.97-817.17 391.06l-97.67 84.07-97.68-84.07c-233.27-201.08-505.19-331.67-817.17-391.06-318.08-60.47-679.08-47.24-1083.73 40.78-43.29 9.31-86.22 8.94-127.37-.71-41.51-9.66-80.15-28.99-115.21-56.89-34.34-27.91-61.18-61.54-79.78-100.19C9.64 3168.62-.01 3126.75-.01 3083.1V450.54c0-95.17 33.98-182.82 91.95-251.52 57.6-67.98 138.46-116.28 232.92-132.03h.71z"
          fill="#fff"
          fillRule="nonzero"
        />
        <path
          className="prefix__fil1"
          d="M3211.44 3781.49l-160.28 259.39-160.29-259.39c50.8-8.58 104.83-13.24 160.29-13.24 55.82 0 109.48 4.66 160.28 13.24zm71.2-1484.44v1481.22l-231.84 374.95-231.85-374.95V2297.05c70.84-18.25 149.19-28.26 231.85-28.26 82.29 0 161 10.02 231.84 28.26z"
        />
        <path
          className="prefix__fil1"
          d="M3041.14 4085.96c14.31-3.58 564.22 764.23-330.95 343.83-503.75-236.49-1116.99-552.42-311.64 194.28-739.16-490.16-517.34-721.65 169.6-369.95 1097.32 562.07 371.73-99.1 472.99-168.52v.36z"
        />
      </g>
    </svg>
  );
}